<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form class="custom-form" ref="bonusForm" @submit.prevent="generateBonus">
            <v-row dense>
                <v-col
                    cols="12"
                    md="4"
                    offset-md="2"
                >
                    <v-card
                        height="100%"
                        :elevation="1"
                    >
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Bonus Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Bonus Name</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="bonus.name"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.name"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Entry Date</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-menu 
                                        max-width="290"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                v-model="bonus.entry_date"
                                                :rules="[() => !!bonus.entry_date || '']"
                                                :error-messages="validation_errors.entry_date"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="bonus.entry_date"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Month</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-combobox
                                        placeholder="Bonus Month"
                                        dense
                                        outlined
                                        hide-details
                                        :items="months"
                                        item-text="name"
                                        item-value="id"
                                        class="custom-form"
                                        v-model="selectedMonth"
                                        @focus="getMonths"
                                        :rules="[() => !!bonus.month_id || '']"
                                        :error-messages="validation_errors.month_id"
                                        append-outer-icon="mdi-plus"
                                        @click:append-outer="$router.push('/month')"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Bonus Percent</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="bonus.bonus_percent"
                                        @keyup="generateDetails()"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.bonus_percent"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Other Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row class="pt-2">
                                <v-col cols="4" class="py-1 caption">Approved By</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox
                                        label="Approved By"
                                        dense
                                        outlined
                                        hide-details
                                        :items="employees"
                                        item-text="display_name"
                                        item-value="id"
                                        class="custom-form"
                                        v-model="selectedApprovedBy"
                                        :rules="[() => !!bonus.approved_by || '']"
                                        :error-messages="validation_errors.approved_by"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Remark</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-textarea
                                        dense
                                        outlined
                                        hide-details
                                        @keyup="generateDetails()"
                                        v-model="bonus.remark"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-toolbar dense color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Bonus Details</v-toolbar-title>
                    </v-toolbar>
                </v-col>
                <v-col cols="12" md="12" class="py-1" id="reportContent">
                    <v-simple-table dense class="record_table">
                        <thead>    
                            <tr class="record_heading_tr">
                                <th>SL.</th>
                                <th>Emp ID</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Department</th>
                                <th>Basic Salary</th>
                                <th>Bonus Percent</th>
                                <th>Bonus Amount</th>
                                <th>Paid</th>
                                <th>Due</th>
                                <th>Remark</th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr v-for="(record, sl) in bonus_details" :key="sl">
                                <td>{{++sl}}</td>
                                <td>{{record.emp_id}}</td>
                                <td>{{record.employee_name}}</td>
                                <td>{{record.designation_name}}</td>
                                <td>{{record.department_name}}</td>
                                <td>{{record.basic_salary}}</td>
                                <td style="width: 10%;">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        @keyup="calculateBonus(record.id)"
                                        v-model.number="record.bonus_percent"
                                    ></v-text-field>
                                </td>
                                <td>{{record.bonus_amount}}</td>
                                <td style="width: 10%;">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        @keyup="calculateDue(record.id)"
                                        v-model.number="record.paid_amount"
                                    ></v-text-field>
                                </td>
                                <td>{{record.due_amount}}</td>
                                <td>
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="record.remark"
                                    ></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                <v-row>
                    <v-col cols="12" md="12" class="text-right pb-0">
                        <v-btn height="30px" dark color="light-blue darken-2 mr-1" :loading="loading" :elevation="1" type="submit">Generate</v-btn>
                        <v-btn height="30px" dark color="deep-orange" :elevation="1" @click="resetForm">Cancel</v-btn>
                    </v-col>
                </v-row>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return{
            employees         : [],
            selectedApprovedBy: null,
            months            : [],
            selectedMonth     : null,
            bonus             : {
                id           : null,
                name         : '',
                month_id     : null,
                entry_date   : this.toISOLocal().substr(0, 10),
                remark       : '',
                bonus_percent: '',
                approved_by  : null,
            },
            loading          : false,
            menu             : false,
            validation_errors: {},
            bonus_details    : [],
        }
    },
    watch: {
        selectedApprovedBy(emp){
            this.bonus.approved_by = null;
            if(!emp || emp.id == undefined){
                return;
            }
            this.bonus.approved_by = emp.id;
        },
        selectedMonth(month){
            this.bonus.month_id  = null;
            if(!month || month.id == undefined){
                return;
            }
            this.bonus.month_id  = month.id;
        }
    },
    async created () {
        await this.getEmployees();
        if(this.$route.params.id) {
            this.getBonuses();
        }else{
            this.generateDetails();
        }
    },
    methods: {
        async getBonuses(){
            let bonus = await this.$store.dispatch('bonus/getBonuses', {
                apiParams: {
                    id: this.$route.params.id,
                },
                options: {
                    returnData: true
                }
            });

            this.bonus = bonus[0];

            this.selectedApprovedBy = {
                id          : this.bonus.approved_by,
                display_name: this.bonus.approved_by_name,
            }

            this.selectedMonth = {
                id  : this.bonus.month_id,
                name: this.bonus.month_name,
            }

            this.bonus_details = await this.$store.dispatch('bonus/getBonusDetails', {
                apiParams: {
                    bonus_id: this.$route.params.id,
                },
                options: {
                    returnData: true
                }
            });
        },
        calculateDue(employee_id){
            this.bonus_details = this.bonus_details.map((e)=>{
                if(e.id == employee_id){
                    e.due_amount   = +e.bonus_amount - +e.paid_amount;
                }
                return e;
            });
        },
        calculateBonus(employee_id){
            this.bonus_details = this.bonus_details.map((e)=>{
                if(e.id == employee_id){
                    let bonus_percent = isNaN(e.bonus_percent) ? 0 : e.bonus_percent;
                    e.bonus_amount = parseFloat((e.basic_salary * bonus_percent) / 100).toFixed(2);
                    e.paid_amount  = e.bonus_amount;
                    e.due_amount   = 0;
                }
                return e;
            });
        },
        generateDetails(){
            this.bonus_details = this.bonus_details.map((e)=>{

                if(!this.$route.params.id) {
                    e.employee_name    = e.name;
                }

                e.bonus_percent    = isNaN(this.bonus.bonus_percent) ? 0 : this.bonus.bonus_percent;
                e.remark           = this.bonus.remark;
                e.bonus_amount     = parseFloat((e.basic_salary * e.bonus_percent) / 100).toFixed(2);
                e.remark           = this.bonus.remark;
                e.paid_amount      = e.bonus_amount;
                e.due_amount       = 0;

                return e;
            })
        },
        async getEmployees(){
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
            if(!this.$route.params.id) {
                this.bonus_details = this.employees.filter(e=> e.bonus_applicable == 1);
            }
        },
        async getMonths(){
            this.months = await this.$store.dispatch('month/getSalaryMonths', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async generateBonus(){

            if(!this.$refs.bonusForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;

            let url = 'generate-bonus';

            if(this.bonus.id != null){
                url = 'update-bonus';
            }

            let data = {
                url    : url,
                bonus  : this.bonus,
                details: this.bonus_details,
            }
            let resObj = await this.$store.dispatch('bonus/generateBonus', data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
            if(resObj.success) {
                this.resetForm();
            }
        },
        resetForm(){
            this.selectedMonth      = null;
            this.selectedApprovedBy = null;

            this.validation_errors = {};

            this.bonus = {
                id           : null,
                name         : '',
                month_id     : null,
                entry_date   : this.toISOLocal().substr(0, 10),
                remark       : '',
                bonus_percent: '',
                approved_by  : null,
            },
            this.generateDetails();
            this.$refs.bonusForm.resetValidation();

            if (this.$route.path !== "/bonus_generate") {
                this.$router.push('/bonus_generate');
            }
        },
    },
}
</script>

<style scoped>
    .v-sheet.v-card:not(#employee_info){
        background: #6ef6f63d !important;
    }

    .theme--light.v-toolbar.v-sheet {
        background-color: #5df0f096 !important;
    }

    .v-card__text .caption{
    color: #000;
    }

    >>>.v-text-field--outlined .v-input__append-outer {
        margin-top: 0 !important;
    }

    >>>.v-text-field--outlined .v-input__append-outer button {
        color: #fff;
        background: #3e2e6b;
    }

    .record_table{
        margin-bottom: 5px;
    }
    .record_table table, .record_table th, .record_table td {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 0 !important;
        
    }
    .record_table th, .record_table td {
        padding: 2px !important;
        font-size: 11px !important;
        text-align: center !important;
        height: 0 !important;
    }
    .record_heading_tr{
        background: #4caf7773;
    }
</style>